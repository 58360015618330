import React, { useEffect, useState } from "react";
import SideBar from "../../components/Global/SideBar";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PauseIcon from "@mui/icons-material/Pause";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { storage } from "../../firebaseConfig";
import { useParams } from "react-router-dom";
import { db } from "../../firebaseConfig";
import {
  setDoc,
  doc,
  collection,
  addDoc,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
// import { } from "firebase/storage";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Howl, Howler } from "howler";
function AddMusic() {
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [audioFile, setAudioFile] = useState(null);
  const setState = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (formErrors[key]) {
      setFormErrors({ ...formErrors, [key]: "" });
    }
  };

  const Submit = async (form) => {
    setIsLoading(true);
    console.log(form);
    const {
      artistName,
      Duration,
      price,

      music,
    } = form;

    const artistNameMaxLength = 30;

    const pricePattern = /^\d{1,6}$/; // Matches 1 to 6 digits

    // Error messages
    const errors = {};

    if (!artistName || artistName.trim() === "") {
      errors.artistName = "Artist name cannot be empty.";
      setIsLoading(false);
    } else if (artistName.length > artistNameMaxLength) {
      errors.artistName = `Cannot exceed ${artistNameMaxLength} characters.`;
      setIsLoading(false);
    }

    if (!price || price.trim() === "") {
      errors.price = "Price cannot be empty.";
      setIsLoading(false);
    } else if (!pricePattern.test(price)) {
      errors.price = "Invalid! Maximum of 6 digits only.";
      setIsLoading(false);
    }

    if (!music) {
      setIsLoading(false);
      toast.error("Music Audio is mandatory", {
        position: "top-right",
        autoClose: 3000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setFormErrors(errors);

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      // Handle validation errors, for example, display error messages
      setIsLoading(false);
      console.error("Validation errors:", errors);
    } else {
      // Proceed with form submission
      try {
        let musicUrl = "";

        try {
          const musicName = music.name;
          const folderName = "music/";
          const musicRef = ref(storage, folderName + musicName);
          console.log(musicRef);
          const uploadBytesRes = await uploadBytes(musicRef, music);
          console.log(uploadBytesRes);
          musicUrl = await getDownloadURL(uploadBytesRes.ref);
          console.log("Musicurl", music);
        } catch (error) {
          console.error("music upload error:", error);
        }
        function generateUniqueID() {
          // Generate a random number between 0 and 9999
          const randomPart = Math.floor(Math.random() * 10000);

          // Get the current timestamp (milliseconds since Unix epoch)
          const timestampPart = Date.now();

          // Combine the random part and timestamp part to create a unique ID
          const uniqueID = `${timestampPart}-${randomPart}`;

          return uniqueID;
        }
        const musicObject = {
          id: generateUniqueID(),
          artistName,
          Duration,
          price,
          music: musicUrl,
        };
        console.log(musicObject);
        const albumDocRef = doc(db, "Album", id);
        const albumDocSnap = await getDoc(albumDocRef);
        const albumData = albumDocSnap.data();
        albumData.music.push(musicObject);
        await updateDoc(albumDocRef, albumData);
        console.log("data successful");
        setIsLoading(false);
        setAudioFile("");
        toast.success(" Music Uploaded Successfully ", {
          position: "top-right",
          autoClose: 3000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        setAudioFile("");
        setIsLoading(false);
        toast.error("Music not Uploaded", {
          position: "top-right",
          autoClose: 3000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("Not Submitted:", error);
      }
    }
  };

  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItem2, setSelectedItem2] = useState("");
  const [selectedItem3, setSelectedItem3] = useState("");
  const [files, setFiles] = useState("");
  const musicFileInputRef = useRef(null);

  const handleMusicFileClick = () => {
    // Trigger the hidden music file input
    musicFileInputRef.current.click();
  };
  const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const formattedDuration = [];

    if (hours > 0) {
      formattedDuration.push(`${hours}h`);
    }
    if (minutes > 0) {
      formattedDuration.push(`${minutes}m`);
    }
    if (seconds > 0) {
      formattedDuration.push(`${seconds}s`);
    }

    return formattedDuration.join(" ");
  };
  const handleMusicFileChange = (e) => {
    const selectedMusicFile = e.target.files[0];

    if (selectedMusicFile) {
      // Set the "music" property with the selected music file

      // Create an audio element
      const audio = new Audio();
      audio.src = URL.createObjectURL(selectedMusicFile);
      setAudioFile(audio.src);
      audio.onloadedmetadata = () => {
        const duration = audio.duration;
        const formattedDuration = formatDuration(duration);
        console.log("Formatted Music duration:", formattedDuration);
        setFormData({
          ...formData,
          Duration: formattedDuration,
          music: selectedMusicFile,
        });
      };

      // Trigger the loading of audio metadata
      audio.load();

      setMusicFile(selectedMusicFile);
    }
  };

  useEffect(() => {
    console.log(formData, "formdata");
  }, [formData]);
  const [musicFile, setMusicFile] = useState(null);
  const handleSelectChange = (event) => {
    console.log(event.target.value);

    setSelectedItem(event.target.value);
    setState("Duration", event.target.value);
  };
  const handleSelectChange2 = (event) => {
    console.log(event.target.value);
    setSelectedItem2(event.target.value);
  };
  const handleSelectChange3 = (event) => {
    console.log(event.target.value);
    setSelectedItem3(event.target.value);
  };
  const fileInputRef = useRef(null);

  const styles = {
    main: {
      padding: "3rem",
    },
    head: {
      paddingY: "0.5rem",
      borderBottom: " 1px solid pink",
    },
    colFlex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    flex1: {
      paddingY: "10px",
      display: "flex",

      flexDirection: "column",
      gap: "0.5rem",
    },
    absolute: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex2: {
      paddingY: "5px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    name: {
      fontFamily: "'Poppins', sans-serif",
      color: "black",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    btn: {
      paddingX: "2.8rem",
      paddingY: "0.5rem",
      borderRadius: "25px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "0.7rem",
      fontWeight: "600",
      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "	#FFAC1C",
      ":hover": {
        backgroundColor: "#FFAC1C",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
  };

  return (
    <div>
      <SideBar>
        <Box sx={styles.main}>
          <Box sx={styles.head}>
            <Typography sx={styles.name}>Add Music</Typography>
          </Box>
          <Grid container paddingTop={2}>
            <Grid item lg={4}>
              <Box sx={styles.flex1}>
                <Box>
                  <Typography sx={styles.name}>Music</Typography>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    height: "180px",
                    width: "180px",
                    border: "1px solid #FFF8DC",
                    backgroundColor: "#FFF0F5",
                    cursor: "pointer",
                  }}
                  onClick={handleMusicFileClick}
                >
                  <Box sx={styles.absolute}>
                    <Box sx={styles.colFlex}>
                      <MusicNoteIcon />

                      <Typography>Upload an Music</Typography>
                      <input
                        type="file"
                        accept="audio/*" // Specify accepted file types, e.g., images
                        ref={musicFileInputRef}
                        style={{ display: "none" }} // Hide the input
                        onChange={handleMusicFileChange}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box>
                  {audioFile ? (
                    <>
                      <audio src={audioFile} controls />
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item lg={12}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>Artist Name</Typography>
                    </Box>
                    <Box>
                      <TextField
                        error={Boolean(formErrors.artistName)}
                        helperText={formErrors.artistName || " "}
                        onChange={(e) => {
                          setState("artistName", e.target.value);
                        }}
                        variant="outlined"
                        sx={{ backgroundColor: "#FFFCFC" }}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={12}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>Price</Typography>
                    </Box>
                    <Box>
                      <TextField
                        error={Boolean(formErrors.price)}
                        helperText={formErrors.price || " "}
                        onChange={(e) => {
                          setState("price", e.target.value);
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={12}>
                  <Box sx={styles.flex2}>
                    <Button
                      onClick={() => {
                        Submit(formData);
                      }}
                      variant="contained"
                      sx={styles.btn}
                    >
                      {isLoading && (
                        <CircularProgress
                          size={20}
                          sx={{ marginRight: "10px", color: "white" }}
                        />
                      )}
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ToastContainer />
      </SideBar>
    </div>
  );
}

export default AddMusic;
