import { Box, Typography } from "@mui/material";
import React from "react";
import img1 from "../../assests/1 copy.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
function Cards({ img }) {
  const style = {
    main: {},
  };
  return (
    <>
      <Box style={{ paddingLeft: "0.4rem", paddingRight: "0.4rem" }}>
        <Box style={{ height: "200px", width: "200px", objectFit: "cover" }}>
          <img src={img} alt="" height={"100%"} width={"100%"} />
        </Box>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ color: "black", fontWeight: "bold" }}>
            Music Title Name
          </Typography>
          <Box>
            <FavoriteBorderIcon />
          </Box>
        </Box>
        <Box>
          <Typography>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Cards;
