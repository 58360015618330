import React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, getDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import MusicCard from "../Music/MusicCard";
import CircularProgress from "@mui/material/CircularProgress";
function AlbumDescription() {
  const navigate = useNavigate();
  const [musicList, setMusicList] = useState([]);
  const [data, setData] = useState({});
  const { id } = useParams();
  const styles = {
    main: {
      display: "flex",
      justifyContent: "center",
      alignitems: "center",
      flexDirection: "column",
      gap: "20px",
      padding: "40px",
    },
    flex1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5rem",
    },

    box: {
      display: "flex",
      justifyContent: "center",
      alignitems: "center",
      flexDirection: "column",
      gap: "20px",
    },
    time: {
      display: "flex",
      gap: "10px",
      flexDirection: "row",
      alignItems: "center",
    },
    font: {
      fontFamily: "'Poppins'",
      color: "black",
      fontSize: "1.5rem",
      fontWeight: "bolder",
    },
    flex: {
      display: "flex",
      flexDirection: "column",

      gap: "30px",
    },
    date: {
      display: "flex",
      gap: "20px",
      flexDirection: "row",
    },
    img: {
      display: "flex",
      gap: "10px",
      flexDirection: "column",
    },
    font1: {
      fontFamily: "'Poppins'",
      fontWeight: "bold",
    },
  };

  async function deleteItemFromArray(arrayField, itemId) {
    try {
      // Reference the document you want to update
      const docRef = doc(db, "Album", id); // Replace "YourCollection" with your collection name

      // Get the current data of the document
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();

      // Check if the array field exists in the document
      if (data[arrayField]) {
        // Find the index of the item with the given itemId
        const indexToRemove = data[arrayField].findIndex(
          (item) => item.id === itemId
        );

        if (indexToRemove !== -1) {
          // Use JavaScript's splice to remove the item at the specified index
          data[arrayField].splice(indexToRemove, 1);

          // Update the document with the modified data
          await updateDoc(docRef, { [arrayField]: data[arrayField] });

          console.log(`Removed item with ID ${itemId}`);
        } else {
          console.log("Item not found in the array.");
        }
      } else {
        console.log("Array field does not exist in the document.");
      }
    } catch (error) {
      console.error("Error removing item from array:", error);
    }
  }

  //   const deleteMusicDocument = async (musicId) => {
  //     const musicDocRef = doc(db, "Music", musicId); // Assuming your collection is named "Music"
  //     const res = await deleteDoc(musicDocRef);
  //     console.log("filter", res);
  //   };
  const handleDelete = async (musicId) => {
    try {
      // Perform the delete operation using Firebase
      await deleteItemFromArray("music", musicId); // Pass the document ID to the delete function
      setMusicList((prevMusicList) =>
        prevMusicList.filter((music) => music.id !== musicId)
      );
    } catch (error) {
      console.error("Error deleting music:", error);
    }
  };
  const getData = async () => {
    try {
      const ref = doc(db, "Album", id);
      const docRef = await getDoc(ref);
      const docData = docRef.data();
      const truncatedDescription =
        docData.description.length > 250
          ? docData.description.substring(0, 250) + "....."
          : docData.description;
      const modifiesData = {
        ...docData,
        truncatedDescription,
      };
      setData(modifiesData);
      console.log("data", data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const fetchMusic = async () => {
      try {
        const albumDocRef = doc(db, "Album", id);
        const albumDocSnapshot = await getDoc(albumDocRef);
        const albumData = albumDocSnapshot.data();
        const musicArray = albumData.music;
        setMusicList(musicArray);
        console.log(musicArray);
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };

    fetchMusic();
  }, []);

  return (
    <>
      <SideBar>
        <Box sx={styles.main}>
          <Box>
            <Typography sx={styles.font} variant="h4">
              Album Description
            </Typography>
          </Box>
          <Grid container display={"flex"} columnSpacing={4}>
            <Grid item lg={3}>
              <Box sx={styles.box}>
                <Box sx={{ height: "100%", width: "100%" }}>
                  <img src={data.image} width={"100%"} alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5}>
              <Box sx={styles.img}>
                <Typography variant="h5" sx={styles.font1}>
                  {data.albumName}
                </Typography>
                <Box>
                  <Typography>{data.truncatedDescription}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box>
            <Box sx={styles.flex}>
              <Box>
                <Button
                  variant="contained"
                  color="error"
                  sx={styles.btn}
                  onClick={() => {
                    navigate(`/AddMusic/${id}`);
                  }}
                >
                  Add Music
                </Button>
              </Box>

              <Box sx={styles.flex1}>
                {musicList?.map((val) => (
                  <MusicCard
                    name={val.albumName}
                    artName={val.artistName}
                    audio={val.audio}
                    price={val.price}
                    duration={val.Duration}
                    musicId={val.id} // Pass the document ID as a prop
                    onDelete={handleDelete} // Pass the delete function as a prop
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </SideBar>
    </>
  );
}

export default AlbumDescription;
