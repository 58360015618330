import React from "react";
import img1 from "../../assests/14.png";
import { Box, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
function Blog({ img }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          gap: "20px",
          width: "70%",
        }}
      >
        <Box>
          <img src={img} alt="" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>Music Title Name</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <Typography>100 Views</Typography>
            <Typography>654 Comments</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Blog;
