import React, { useRef } from "react";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";

import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { storage } from "../../firebaseConfig";
import { db } from "../../firebaseConfig";
import { setDoc, doc, collection, addDoc } from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import "react-toastify/dist/ReactToastify.css";
function AddVideos() {
  const styles = {
    main: {
      padding: "3rem",
    },
    head: {
      paddingY: "0.5rem",
      borderBottom: " 1px solid pink",
    },
    colFlex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    flex1: {
      paddingY: "10px",
      display: "flex",

      flexDirection: "column",
      gap: "0.5rem",
    },
    absolute: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex2: {
      paddingY: "5px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    btn: {
      paddingX: "2.8rem",
      paddingY: "0.5rem",
      borderRadius: "25px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "0.7rem",
      fontWeight: "600",
      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "	#FFAC1C",
      ":hover": {
        backgroundColor: "#FFAC1C",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
    name: {
      fontFamily: "'Poppins', sans-serif",
      color: "black",
      fontSize: "1rem",
      fontWeight: "bold",
    },
  };
  const [videoFile, setVideofile] = useState();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const setState = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (formErrors[key]) {
      setFormErrors({ ...formErrors, [key]: "" });
    }
  };

  const Submit = async (form) => {
    setIsLoading(true);
    console.log(form);
    const {
      Name,

      description,

      Video,
    } = form;
    const albumNameMaxLength = 30;

    const descriptionMaxLength = 1000;
    // Matches 1 to 6 digits

    // Error messages
    const errors = {};

    if (!Name || Name.trim() === "") {
      setIsLoading(false);
      errors.Name = "Album name cannot be empty.";
    } else if (Name.length > albumNameMaxLength) {
      setIsLoading(false);
      errors.Name = `Cannot exceed ${albumNameMaxLength} characters.`;
    }

    if (!description || description.trim() === "") {
      setIsLoading(false);
      errors.description = "Description cannot be empty.";
    } else if (description.length > descriptionMaxLength) {
      setIsLoading(false);
      errors.description = `Cannot exceed ${descriptionMaxLength} characters.`;
    }

    if (!Video) {
      toast.error("Video is mandatory", {
        position: "top-right",
        autoClose: 3000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setFormErrors(errors);

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      // Handle validation errors, for example, display error messages
      console.error("Validation errors:", errors);
    } else {
      // Proceed with form submission
      try {
        let url = "";

        try {
          const imageName = Video.name;
          const folderName = "Video/";
          const imageRef = ref(storage, folderName + imageName);
          console.log(imageRef);
          const uploadBytesRes = await uploadBytes(imageRef, Video);
          console.log(uploadBytesRes);
          url = await getDownloadURL(uploadBytesRes.ref);
          console.log("Imageurl", url);
        } catch (error) {
          console.error("Image upload error:", error);
        }

        const res = await addDoc(collection(db, "Videos"), {
          Name: Name,

          description: description,

          Video: url,
        });
        setIsLoading(false);
        toast.success("Video Added", {
          position: "top-right",
          autoClose: 3000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        toast.error("Video Not Uploaded", {
          position: "top-right",
          autoClose: 2000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("Not Submitted:", error);
        setIsLoading(false);
      }
    }
  };

  const [files, setFiles] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Handle the selected file here

      setVideofile(selectedFile);
      setFiles(selectedFile);

      setState("Video", selectedFile);

      console.log("Selected file:", selectedFile);
    }
  };

  const fileInputRef = useRef(null);

  const handleBoxClick = () => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  return (
    <SideBar>
      <Box sx={styles.main}>
        <Box sx={styles.head}>
          <Typography sx={styles.name}>Add Video</Typography>
        </Box>
        <Grid container paddingTop={2}>
          <Grid item lg={4}>
            <Box sx={styles.flex1}>
              <Box
                sx={{
                  position: "relative",
                  height: "180px",
                  width: "180px",
                  border: "1px solid #FFF8DC",
                  backgroundColor: "#FFF0F5",
                  cursor: "pointer",
                }}
                onClick={handleBoxClick}
              >
                <Box sx={styles.absolute}>
                  <Box sx={styles.colFlex}>
                    <InsertPhotoIcon />

                    <Typography>Upload an Video</Typography>
                    <input
                      type="file"
                      accept="video/*" //
                      ref={fileInputRef}
                      style={{ display: "none" }} // Hide the input
                      onChange={handleFileChange}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {videoFile && (
              <Box
                sx={{
                  width: "50%",
                  height: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <video
                  src={URL.createObjectURL(videoFile)}
                  controls
                  alt=""
                  height={"100%"}
                  width={"100%"}
                  style={{ objectFit: "cover", borderRadius: "15px" }}
                />
              </Box>
            )}
          </Grid>
          <Grid item lg={8}>
            <Grid container columnSpacing={5} rowSpacing={1}>
              <Grid item lg={12}>
                <Box sx={styles.flex1}>
                  <Box>
                    <Typography sx={styles.name}>Video Name</Typography>
                  </Box>
                  <Box>
                    <TextField
                      error={Boolean(formErrors.Name)}
                      helperText={formErrors.Name || " "}
                      onChange={(e) => {
                        setState("Name", e.target.value);
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: "#FFFCFC" }}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={12}>
                <Box sx={styles.flex1}>
                  <Box>
                    <Typography sx={styles.name}>Album Description</Typography>
                  </Box>
                  <Box>
                    <TextField
                      error={Boolean(formErrors.description)}
                      helperText={formErrors.description || " "}
                      onChange={(e) => {
                        setState("description", e.target.value);
                      }}
                      sx={{ backgroundColor: "#FFFCFC" }}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={7}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={12}>
                <Box sx={styles.flex2}>
                  <Button
                    onClick={() => {
                      Submit(formData);
                    }}
                    variant="contained"
                    sx={styles.btn}
                  >
                    {isLoading && (
                      <CircularProgress
                        size={20}
                        sx={{ marginRight: "10px", color: "white" }}
                      />
                    )}
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </SideBar>
  );
}

export default AddVideos;
