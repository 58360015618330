import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Grid, Typography, Button } from "@mui/material";
import img1 from "../../assests/11.png";
import { StayPrimaryLandscapeRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EditIcon from "@mui/icons-material/Edit";

const styles = {
  main: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: " #FFFFE0",
  },
  time: {
    display: "flex",
    gap: "2px",
    flexDirection: "row",
  },
  cont: {
    width: "95%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
    paddingY: "5px",
  },
  img: {
    height: "150px",
    width: "100%",
  },
  desc2: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  },
  desc: {
    display: "flex",

    flexDirection: "column",
  },
  date: {
    display: "flex",
    gap: "10px",
    flexDirection: "row",
  },
};

function EventCard({
  date,
  time,
  name,
  img,
  month,
  desc,
  Description,
  id,
  onDelete,
}) {
  const navigate = useNavigate();
  return (
    <div>
      <Box sx={styles.main}>
        <Box sx={styles.cont}>
          <Box sx={styles.img}>
            <img
              src={img}
              height={"100%"}
              width={"100%"}
              style={{ objectFit: "cover", borderRadius: "8px" }}
              alt=""
            />
          </Box>
          <Box sx={styles.desc}>
            {/* <Box sx={styles.date}>
              <Box
                sx={{
                  height: "60px",
                  width: "60px",
                  backgroundColor: "#FFB84C",
                  color: "white",
                  fontWeight: "bolder",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {date}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontFamily: "'Bebas Neue', sans-serif" }}
                >
                  {month}
                </Typography>

                <Box sx={styles.time}>
                  <AccessTimeIcon fontSize="small" />
                  <Typography>{time}</Typography>
                </Box>
              </Box>
            </Box> */}
          </Box>
          <Box sx={styles.desc2}>
            <Box>
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontWeight: "bolder",
                }}
              >
                {name}
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "black",
                  fontFamily: "'Poppins'",
                }}
              >
                <Typography>{Description}</Typography>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                onClick={() => {
                  navigate(`/EventDesc/${id}`);
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "'Poppins'",
                    cursor: "pointer",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {`Read more >`}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/updateEvent/${id}`);
                  }}
                >
                  <EditIcon />
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    onDelete(id);
                  }}
                >
                  <DeleteIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default EventCard;
