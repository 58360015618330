import React, { useEffect, useState } from "react";
import SideBar from "../../components/Global/SideBar";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

// import htmlToDraft from 'html-to-draftjs';

import { Editor } from "react-draft-wysiwyg";

import PauseIcon from "@mui/icons-material/Pause";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { storage } from "../../firebaseConfig";
import { db } from "../../firebaseConfig";
import {
  setDoc,
  doc,
  collection,
  addDoc,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";

import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import draftToHtml from "draftjs-to-html";
// import htmlToDraft from 'html-to-draftjs';
import { convertToRaw } from "draft-js";
import { EditorState } from "draft-js";
import { useParams } from "react-router-dom";
function UpdateEvent() {
  const { id } = useParams();
  const [value, onChange] = useState("10:00");
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [files, setFiles] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const getEditData = async () => {
    const snap = doc(db, "Event", id);
    try {
      const document = await getDoc(snap);
      const data = document.data();
      return data;
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getEditData().then((data) => {
      setFormData(data);
      setUploadedImage(data.image);
      const date1 = new Date(data.date.seconds * 1000);
      //   setStartDate(date1);
      setStartDate(date1);
      onChange(data.time);
      console.log(date1, "date");
      console.log(data, "current blogdata");
    });
  }, []);
  const setState = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (formErrors[key]) {
      setFormErrors({ ...formErrors, [key]: "" });
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const Submit = async (form) => {
    setIsLoading(true);
    console.log(form);
    const { Name, time, date, description, location, image } = form;
    const albumNameMaxLength = 30;
    const artistNameMaxLength = 30;
    const descriptionMaxLength = 1000;
    const locationMaxLength = 100;
    const pricePattern = /^\d{1,6}$/; // Matches 1 to 6 digits

    // Error messages
    const errors = {};

    if (!Name || Name.trim() === "") {
      errors.Name = "Album name cannot be empty.";
      setIsLoading(false);
    } else if (Name.length > albumNameMaxLength) {
      setIsLoading(false);
      errors.Name = `Cannot exceed ${albumNameMaxLength} characters.`;
    }

    if (!location || location.trim() === "") {
      errors.location = "Location name cannot be empty.";
      setIsLoading(false);
    } else if (location.length > locationMaxLength) {
      setIsLoading(false);
      errors.location = `Cannot exceed ${locationMaxLength} characters.`;
    }

    if (!description || description.trim() === "") {
      setIsLoading(false);
      errors.description = "Description cannot be empty.";
    } else if (description.length > descriptionMaxLength) {
      setIsLoading(false);
      errors.description = `Cannot exceed ${descriptionMaxLength} characters.`;
    }

    if (!image) {
      setIsLoading(false);
      errors.blogCover = "Image Canont be empty";
      toast.error("Image is mandatory", {
        position: "top-right",
        autoClose: 3000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setFormErrors(errors);

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      // Handle validation errors, for example, display error messages
      console.error("Validation errors:", errors);
    } else {
      // Proceed with form submission
      try {
        const res = doc(db, "Event", id);
        updateDoc(res, form);
        setIsLoading(false);
        console.log("Music Added:", res);
        toast.success("Event updated", {
          position: "top-right",
          autoClose: 3000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("Not Submitted:", error);
        toast.error("Event Not Updated", {
          position: "top-right",
          autoClose: 2000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      }
    }
  };

  const fileInputRef = useRef(null);

  const handleBoxClick = () => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Handle the selected file here
      setFiles(selectedFile);
      let url = "";

      try {
        const imageName = selectedFile.name;
        const folderName = "event/";
        const imageRef = ref(storage, folderName + imageName);
        console.log(imageRef);
        const uploadBytesRes = await uploadBytes(imageRef, selectedFile);
        console.log(uploadBytesRes);
        url = await getDownloadURL(uploadBytesRes.ref);
        console.log("Imageurl", url);
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setState("image", url);

      console.log("Selected file:", selectedFile);
      setUploadedImage(URL.createObjectURL(selectedFile));
    }
  };
  const styles = {
    main: {
      padding: "3rem",
    },
    head: {
      paddingY: "0.5rem",
      borderBottom: " 1px solid pink",
    },
    colFlex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    flex1: {
      paddingY: "10px",
      display: "flex",

      flexDirection: "column",
      gap: "0.5rem",
    },
    absolute: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex2: {
      paddingY: "5px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    name: {
      fontFamily: "'Poppins', sans-serif",
      color: "black",
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
    btn: {
      paddingX: "2.8rem",
      paddingY: "0.5rem",
      borderRadius: "25px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "0.7rem",
      fontWeight: "600",
      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "	#FFAC1C",
      ":hover": {
        backgroundColor: "#FFAC1C",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
  };
  return (
    <div>
      <SideBar>
        <Box sx={styles.main}>
          <Box sx={styles.head}>
            <Typography sx={styles.name}>Edit Event</Typography>
          </Box>
          <Grid container paddingTop={2}>
            <Grid item lg={4}>
              <Box sx={styles.flex1}>
                <Box
                  sx={{
                    position: "relative",
                    height: "180px",
                    width: "180px",
                    border: "1px solid #FFF8DC",
                    backgroundColor: "#FFF0F5",
                    cursor: "pointer",
                  }}
                  onClick={handleBoxClick}
                >
                  <Box sx={styles.absolute}>
                    <Box sx={styles.colFlex}>
                      {uploadedImage ? (
                        <img
                          src={uploadedImage}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <>
                          <InsertPhotoIcon />

                          <Typography>Upload an Image</Typography>
                        </>
                      )}
                      <input
                        type="file"
                        accept="image/*" // Specify accepted file types, e.g., images
                        ref={fileInputRef}
                        style={{ display: "none" }} // Hide the input
                        onChange={handleFileChange}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Grid container columnSpacing={5} rowSpacing={1}>
                <Grid item lg={6}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>Event Name</Typography>
                    </Box>
                    <Box>
                      <TextField
                        value={formData.Name}
                        error={Boolean(formErrors.Name)}
                        helperText={formErrors.Name || " "}
                        onChange={(e) => {
                          setState("Name", e.target.value);
                        }}
                        variant="outlined"
                        sx={{ backgroundColor: "#FFFCFC" }}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>Add Location</Typography>
                    </Box>
                    <Box>
                      <TextField
                        value={formData.location}
                        error={Boolean(formErrors.location)}
                        helperText={formErrors.location || " "}
                        onChange={(e) => {
                          setState("location", e.target.value);
                        }}
                        variant="outlined"
                        sx={{ backgroundColor: "#FFFCFC" }}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>Date</Typography>
                    </Box>
                    <Box>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          setState("date", date);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={6}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>Start at</Typography>
                    </Box>
                    <Box>
                      <TimePicker
                        onChange={(newTime) => {
                          onChange(newTime);
                          const timeParts = newTime.split(":");
                          const hour = parseInt(timeParts[0], 10);
                          const minute = timeParts[1];
                          const ampm = hour >= 12 ? "PM" : "AM";
                          const formattedTime = `${
                            hour % 12 || 12
                          }:${minute} ${ampm}`;
                          setState("time", formattedTime); // Use the 'setTime' function to update the 'time' state
                        }}
                        value={value} // 'value' prop is used to set the currently selected time
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={12}>
                  <Box sx={styles.flex1}>
                    <Box>
                      <Typography sx={styles.name}>
                        Event Description
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        value={formData.description}
                        error={Boolean(formErrors.description)}
                        helperText={formErrors.description || " "}
                        onChange={(e) => {
                          setState("description", e.target.value);
                        }}
                        sx={{ backgroundColor: "#FFFCFC" }}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={7}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={12}>
                  <Box sx={styles.flex2}>
                    <Button
                      onClick={() => {
                        Submit(formData);
                      }}
                      variant="contained"
                      sx={styles.btn}
                    >
                      {isLoading && (
                        <CircularProgress
                          size={20}
                          sx={{ marginRight: "10px", color: "white" }}
                        />
                      )}
                      Update
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <ToastContainer />
      </SideBar>
    </div>
  );
}

export default UpdateEvent;
