import React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Grid, Typography, Button } from "@mui/material";
import img1 from "../../assests/11.png";
import { useNavigate } from "react-router";
import { doc, deleteDoc, collection, getDocs } from "@firebase/firestore";
import { db } from "../../firebaseConfig";
import EventCard from "./EventCard";

function Event() {
  const navigate = useNavigate();
  const styles = {
    main: {
      padding: "3rem",
    },
    btn: {
      paddingX: "2rem",
      paddingY: "0.5rem",
      borderRadius: "25px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "0.8rem",
      fontWeight: "600",
      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "#990000",
      ":hover": {
        backgroundColor: "#990000",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
    head: {
      paddingY: "0.5rem",

      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
    colFlex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    flex1: {
      paddingY: "10px",
      display: "flex",

      flexDirection: "column",
      gap: "0.5rem",
    },
    absolute: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex2: {
      paddingY: "5px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
  };
  const [eventList, setEventList] = useState([]);
  const handleDelete = async (Id) => {
    try {
      // Perform the delete operation using Firebase
      await deleteDocument(Id); // Pass the document ID to the delete function
      setEventList((prevEventList) =>
        prevEventList.filter((blog) => blog.id !== Id)
      );
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const deleteDocument = async (Id) => {
    const musicDocRef = doc(db, "Event", Id); // Assuming your collection is named "Music"
    const res = await deleteDoc(musicDocRef);
    console.log("filter", res);
  };
  useEffect(() => {
    const eventData = async () => {
      try {
        const blogCollection = collection(db, "Event");
        const querySnapShot = await getDocs(blogCollection);
        const eventData = querySnapShot.docs.map((doc) => {
          const data = doc.data();
          const dateInSeconds = data.date; // Assuming data.date is in seconds
          const timezoneOffsetInMilliseconds =
            new Date().getTimezoneOffset() * 60 * 1000;
          const dateInMillis =
            dateInSeconds * 1000 - timezoneOffsetInMilliseconds; // Adjust for timezone offset
          const date = new Date(dateInMillis);
          date.setUTCHours(0, 0, 0, 0);
          const monthName = new Intl.DateTimeFormat("en-US", {
            month: "long",
          }).format(date);
          const dateNumber = date.getDate();

          // Convert HTML description to plain text
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(data.description, "text/html");
          const textDescription = htmlDoc.body.textContent || "";

          // Truncate description to 30 characters and add ellipsis if too long
          const truncatedDescription =
            textDescription.length > 40
              ? textDescription.substring(0, 40) + "....."
              : textDescription;

          return {
            ...data,
            id: doc.id,
            monthName,
            dateNumber,
            truncatedDescription,
          };
        });
        setEventList(eventData);
        console.log(eventData);
        console.log(eventData[0].date, "date");
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };

    // Call the function to fetch and process the data
    eventData();
  }, []);

  return (
    <div>
      <SideBar>
        <Box sx={styles.main}>
          <Box sx={styles.head}>
            <Typography
              sx={{
                color: "black",
                fontSize: "1.4rem",
                fontWeight: "700",
                color: "black",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Events
            </Typography>
            <Button
              sx={styles.btn}
              variant="contained"
              onClick={() => {
                navigate("/AddEvent");
              }}
            >
              Add New Event
            </Button>
          </Box>
          <Grid container columnSpacing={3}>
            {eventList.map((val) => (
              <Grid item lg={3}>
                <EventCard
                  Description={val.truncatedDescription}
                  name={val.Name}
                  img={val.image}
                  date={val.dateNumber}
                  time={val.time}
                  month={val.monthName}
                  desc={val.description}
                  id={val.id}
                  onDelete={handleDelete}
                />
              </Grid>
            ))}
          </Grid>
          {/* <Grid container paddingTop={4}>
            {videoList.map((val) => (
              <Grid item lg={3}>
                <VideoCard name={val.Name} vid={val.Video} />
              </Grid>
            ))}
          </Grid> */}
        </Box>
      </SideBar>
    </div>
  );
}

export default Event;
