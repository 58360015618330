import React from "react";
import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
function VideoCard({ name, vid, id, onDelete }) {
  const styles = {
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
      width: "100%",
      height: "100%",
    },
    font: {
      color: "black",
      fontSize: "1.2rem",
      fontWeight: "600",
      color: "black",
      fontFamily: "'Poppins', sans-serif",
    },
  };
  const navigate = useNavigate();
  return (
    <div>
      <Box sx={styles.box}>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <video
            src={vid}
            controls
            alt=""
            height={"100%"}
            width={"90%"}
            style={{ objectFit: "cover", borderRadius: "15px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "90%",
          }}
        >
          <Box>
            <Typography sx={styles.font}>{name}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/updateVideos/${id}`);
              }}
            >
              <EditIcon />
            </Box>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                onDelete(id);
              }}
            >
              <DeleteIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default VideoCard;
