import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
function AlbumCard({ name, img, id, onDelete }) {
  const styles = {
    box: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
      width: "100%",
      height: "100%",
    },
    font: {
      color: "black",
      fontSize: "1.2rem",
      fontWeight: "600",
      color: "black",
      fontFamily: "'Poppins', sans-serif",
    },
  };
  const navigate = useNavigate();

  return (
    <div>
      <Box sx={styles.box}>
        <Box
          sx={{ width: "80%" }}
          onClick={() => {
            console.log("click", id);
            navigate(`/AlbumDesc/${id}`);
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <img
              src={img}
              alt=""
              height={"100%"}
              width={"100%"}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "10px",
                border: "2px solid #FFAC1C",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            flexDirection: "row",
          }}
        >
          <Box>
            <Typography sx={styles.font}>{name}</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/editAlbum/${id}`);
              }}
            >
              <EditIcon />
            </Box>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                onDelete(id);
              }}
            >
              <DeleteIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default AlbumCard;
