import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import logo from "../../assests/logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState, useEffect } from "react";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUser } from "../../Redux/UserSlice";
import { store } from "../../Redux/configureStore";
function App({ children }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const { collapseSidebar } = useProSidebar();
  const [active, setActive] = useState("Dashboard");
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const handleMenuItemClick = () => {
    collapseSidebar();
  };

  useEffect(() => {
    // Function to close the sidebar when clicking anywhere outside of it
    const closeSidebarOnClickOutside = (e) => {
      if (!drawerOpen && !e.target.closest(".hello")) {
        // Close the sidebar if it's open and the click is outside of the sidebar area
        collapseSidebar();
        setDrawerOpen(true);
      }
    };

    if (!drawerOpen) {
      // Attach a click event listener to the entire document
      document.addEventListener("click", closeSidebarOnClickOutside);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", closeSidebarOnClickOutside);
    };
  }, [!drawerOpen, collapseSidebar]);
  // const toggleDrawer2 = () => {
  //   // Collapse the sidebar

  //   console.log(drawerOpen, "drawer");
  //   if (drawerOpen === "true") {
  //     collapseSidebar();
  //   } else {
  //     setDrawerOpen(false);
  //   }
  // };

  const toggleDrawer = () => {
    collapseSidebar(); // Collapse the sidebar

    setDrawerOpen(!drawerOpen); // Toggle the drawer state
  };
  return (
    <div
      id="app"
      style={{
        height: "100vh",
        display: "flex",
        backgroundColor: " #FDA10B !important",
      }}
    >
      <Sidebar
        breakPoint="sm"
        defaultCollapsed={true}
        className="hello"
        style={{
          height: "100vh",
          backgroundColor: " #FDA10B !important",
          position: "fixed",
          zIndex: "99",
        }}
      >
        <Menu>
          <MenuItem
            className="item"
            sx={{
              ":hover": {
                backgroundColor: "#FDA10B !important",
                color: "white",
                transition: "all ease 0.5s",
              },
            }}
            icon={
              <MenuOutlinedIcon
                sx={{
                  color: "white",
                }}
              />
            }
            onClick={() => {
              toggleDrawer();
            }}
            style={{ textAlign: "center", color: "black" }}
          >
            <h2 style={{ color: "white" }}>Bizpro</h2>
          </MenuItem>
          {!drawerOpen && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                style={{ height: "150px", width: "160px" }}
                alt=""
              />
            </Box>
          )}
          <MenuItem
            sx={{
              ":hover": {
                backgroundColor: "#FDA10B !important",
                color: "white",
                transition: "all ease 0.5s",
              },
            }}
            onClick={() => {
              navigate("/");
            }}
            icon={<HomeOutlinedIcon />}
          >
            Dashboard
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate("/blog");
              setActive("blog");
            }}
            // sx={active === "blog" ? styles.active : null}
            icon={<ContactsOutlinedIcon />}
          >
            Blogs
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/finance");
            }}
            icon={<ReceiptOutlinedIcon />}
          >
            Finance
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/video");
            }}
            icon={<SlowMotionVideoIcon />}
          >
            Videos
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/Event");
            }}
            icon={<ReceiptOutlinedIcon />}
          >
            Events
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate("/album");
            }}
            icon={<CollectionsIcon />}
          >
            Album
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuItemClick();
              store.dispatch(setUser(""));
            }}
            icon={<AccountCircleIcon />}
          >
            Logout
          </MenuItem>
        </Menu>
      </Sidebar>
      <div
        style={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
          margin: "2rem",
        }}
      >
        <Container
          sx={{
            marginLeft: "7rem",
            backgroundColor: "white",
            border: "1px solid ",
            borderRadius: "30px",
            // marginTop: "4rem",
            height: "100%",
            maxWidth: { xl: "1700px", lg: "1200px", md: "1000px", sm: "600px" },
          }}
        >
          {children}
        </Container>
      </div>
    </div>
  );
}

export default App;
