import React, { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Grid, Typography, Button, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { collection, getDocs, getDoc, doc } from "@firebase/firestore";
import { db } from "../../firebaseConfig";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
function BlogDescription() {
  const [data, setData] = useState({});
  const { id } = useParams();
  const styles = {
    main: {
      display: "flex",
      justifyContent: "center",
      alignitems: "center",
      flexDirection: "column",
      gap: "20px",
      padding: "20px",
    },

    box: {
      display: "flex",
      justifyContent: "center",
      alignitems: "center",
      flexDirection: "column",
      gap: "30px",
    },
    time: {
      display: "flex",
      gap: "10px",
      flexDirection: "row",
      alignItems: "center",
    },
    font: {
      fontFamily: "'Poppins'",
      color: "black",
      fontSize: "2rem",
      fontWeight: "bolder",
    },
    date: {
      display: "flex",
      gap: "10px",
      flexDirection: "row",
    },
    img: {
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    font1: {
      fontFamily: "'Poppins'",
      fontWeight: "bold",
    },
  };
  const getData = async () => {
    try {
      const ref = doc(db, "Blog", id);
      const docRef = await getDoc(ref);
      const docData = docRef.data();
      const dateInSeconds = docData.date; // Assuming data.date is in seconds
      const timezoneOffsetInMilliseconds =
        new Date().getTimezoneOffset() * 60 * 1000;
      const dateInMillis = dateInSeconds * 1000 - timezoneOffsetInMilliseconds; // Adjust for timezone offset
      const date = new Date(dateInMillis);

      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(date);
      const dateNumber = date.getDate();
      const modifiedData = {
        ...docData,
        monthName,
        dateNumber,
      };

      setData(modifiedData); // Set the state here to update the component
      console.log(modifiedData);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const renderHTML = (htmlString) => {
    return { __html: htmlString };
  };
  return (
    <>
      <SideBar>
        <Box sx={styles.main}>
          <Box>
            <Typography sx={styles.font} variant="h3">
              Blog Description
            </Typography>
          </Box>
          <Grid container display={"flex"} columnSpacing={4}>
            <Grid item lg={6}>
              <Box sx={styles.box}>
                <Box sx={{ height: "100%", width: "100%" }}>
                  <img src={data.image} width={"100%"} alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={styles.img}>
                <Typography variant="h5" sx={styles.font1}>
                  {data.Name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                  }}
                >
                  <Box sx={styles.date}>
                    <Box
                      sx={{
                        height: "100px",
                        width: "100px",
                        backgroundColor: "#FFB84C",
                        color: "white",
                        fontWeight: "bolder",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "7px",
                      }}
                    >
                      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                        {data.dateNumber}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{ fontFamily: "'Bebas Neue', sans-serif" }}
                      >
                        {data.monthName}
                      </Typography>

                      <Box sx={styles.time}>
                        <AccessTimeIcon />
                        <Typography variant="h6">{data.time}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography marginTop={2}>
                  <div dangerouslySetInnerHTML={renderHTML(data.description)} />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SideBar>
    </>
  );
}

export default BlogDescription;
