import React from "react";
import SideBar from "../../components/Global/SideBar";
import { Typography, Box, Grid } from "@mui/material";
import Cards from "./Cards";
import Card from "./Card";
import img18 from "../../assests/18.png";
import img19 from "../../assests/19.png";
import img20 from "../../assests/20.png";
import img21 from "../../assests/21.png";
import img22 from "../../assests/22.png";

import { useState } from "react";
import img1 from "../../assests/1 copy.png";
import img2 from "../../assests/2.png";
import img3 from "../../assests/3.png";
import img4 from "../../assests/4.png";
import img5 from "../../assests/5.png";
import img6 from "../../assests/6.png";
import img7 from "../../assests/7.png";
import img8 from "../../assests/8.png";
import img9 from "../../assests/9.png";
import img10 from "../../assests/10.png";
import img11 from "../../assests/11.png";
import img12 from "../../assests/12.png";
import img13 from "../../assests/13.png";
import img15 from "../../assests/15.png";
import img16 from "../../assests/16.png";
import img17 from "../../assests/17.png";
import ReactSimplyCarousel from "react-simply-carousel";
import MusicCard from "./MusicCard";
import Blog from "./Blog";
import Finance from "./Finance";
// import SwiperSlider from "./SwiperSlider";
function Dashboard() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [activeSlideIndex2, setActiveSlideIndex2] = useState(0);
  const [activeSlideIndex3, setActiveSlideIndex3] = useState(0);
  const [activeSlideIndex4, setActiveSlideIndex4] = useState(0);

  const styles = {
    main: {},
    flex: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      gap: "0.5rem",
      width: "100%",
    },
    desc: {
      fontSize: "1.5rem",
      fontWeight: "700",
      color: "black",
      fontFamily: "'Poppins', sans-serif",
    },
    carsoueltext: {
      display: "flex",
      alignItems: "start",
      justifyContent: "start",
    },
  };
  return (
    <div>
      <SideBar>
        <Box>
          <Grid
            container
            padding={2}
            columnSpacing={4}
            rowSpacing={3}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Grid item xl={6} lg={6}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Best Selling Music</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    overflowX: "scroll",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    " ::-webkit-scrollbar ": {
                      width: "12px",
                      height: "5px",
                    },

                    /* Track (the area where the scrollbar moves) */
                    "::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },

                    /* Handle (the draggable part of the scrollbar) */
                    "::-webkit-scrollbar-thumb": {
                      background: "#888",
                      borderRadius:
                        "6px" /* Rounded corners for the scrollbar handle */,
                    },

                    /* Handle on hover */
                    "::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  }}
                >
                  <Cards img={img1} />
                  <Cards img={img2} />
                  <Cards img={img3} />
                  <Cards img={img4} />
                  <Cards img={img5} />
                  <Cards img={img6} />
                  <Cards img={img6} />
                  <Cards img={img7} />
                  <Cards img={img8} />
                  <Cards img={img9} />
                  <Cards img={img10} />
                </Box>
              </Box>
            </Grid>

            <Grid item xl={6} lg={6}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Best Listening Music</Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    overflowX: "scroll",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "2rem",
                    " ::-webkit-scrollbar ": {
                      width: "12px",
                      height: "5px",
                    },

                    /* Track (the area where the scrollbar moves) */
                    "::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },

                    /* Handle (the draggable part of the scrollbar) */
                    "::-webkit-scrollbar-thumb": {
                      background: "#888",
                      borderRadius:
                        "6px" /* Rounded corners for the scrollbar handle */,
                    },

                    /* Handle on hover */
                    "::-webkit-scrollbar-thumb:hover": {
                      background: "#555",
                    },
                  }}
                >
                  <Cards img={img1} />
                  <Cards img={img2} />
                  <Cards img={img3} />
                  <Cards img={img4} />
                  <Cards img={img5} />
                  <Cards img={img6} />
                  <Cards img={img6} />
                  <Cards img={img7} />
                  <Cards img={img8} />
                  <Cards img={img9} />
                  <Cards img={img10} />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Most New Videos</Typography>
                </Box>
                <ReactSimplyCarousel
                  containerProps={{
                    style: {
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  }}
                  activeSlideIndex={activeSlideIndex3}
                  onRequestChange={setActiveSlideIndex3}
                  itemsToShow={1}
                  itemsToScroll={1}
                  forwardBtnProps={{
                    //here you can also pass className, or any other button element attributes
                    style: {
                      position: "absolute",
                      top: 5,
                      right: 110,
                      alignSelf: "center",
                      background: "grey",
                      border: "none",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                      fontSize: "20px",
                      height: 30,
                      lineHeight: 1,
                      textAlign: "center",
                      width: 30,
                    },
                    children: <span>{`>`}</span>,
                  }}
                  backwardBtnProps={{
                    //here you can also pass className, or any other button element attributes
                    style: {
                      position: "absolute",
                      top: 5,
                      right: 150,
                      alignSelf: "center",
                      background: "orange",
                      border: "none",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                      fontSize: "20px",
                      height: 30,
                      lineHeight: 1,
                      textAlign: "center",
                      width: 30,
                    },
                    children: <span>{`<`}</span>,
                  }}
                  responsiveProps={[
                    {
                      itemsToShow: 3,
                      itemsToScroll: 2,
                      minWidth: 768,
                    },
                  ]}
                  speed={400}
                  easing="linear"
                >
                  {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

                  <Card img={img6} />

                  <Card img={img7} />

                  <Card img={img8} />

                  <Card img={img6} />

                  <Card img={img7} />

                  <Card img={img8} />
                </ReactSimplyCarousel>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Most Selling Video</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <MusicCard />
                  <MusicCard />
                  <MusicCard />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={8}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Most New Videos</Typography>
                </Box>
                <ReactSimplyCarousel
                  containerProps={{
                    style: {
                      width: "100%",
                      justifyContent: "space-between",
                    },
                  }}
                  activeSlideIndex={activeSlideIndex4}
                  onRequestChange={setActiveSlideIndex4}
                  itemsToShow={1}
                  itemsToScroll={1}
                  forwardBtnProps={{
                    //here you can also pass className, or any other button element attributes
                    style: {
                      position: "absolute",
                      top: 5,
                      right: 110,
                      alignSelf: "center",
                      background: "grey",
                      border: "none",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                      fontSize: "20px",
                      height: 30,
                      lineHeight: 1,
                      textAlign: "center",
                      width: 30,
                    },
                    children: <span>{`>`}</span>,
                  }}
                  backwardBtnProps={{
                    //here you can also pass className, or any other button element attributes
                    style: {
                      position: "absolute",
                      top: 5,
                      right: 150,
                      alignSelf: "center",
                      background: "orange",
                      border: "none",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                      fontSize: "20px",
                      height: 30,
                      lineHeight: 1,
                      textAlign: "center",
                      width: 30,
                    },
                    children: <span>{`<`}</span>,
                  }}
                  responsiveProps={[
                    {
                      itemsToShow: 3,
                      itemsToScroll: 2,
                      minWidth: 768,
                    },
                  ]}
                  speed={400}
                  easing="linear"
                >
                  {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

                  <Card img={img9} />

                  <Card img={img10} />

                  <Card img={img11} />

                  <Card img={img12} />

                  <Card img={img13} />

                  <Card img={img8} />
                </ReactSimplyCarousel>
              </Box>
            </Grid>
            <Grid item lg={4}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Most View Blog</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Blog img={img15} />
                  <Blog img={img16} />
                  <Blog img={img17} />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12}>
              <Box sx={styles.flex}>
                <Box>
                  <Typography sx={styles.desc}>Finance</Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: "60px",
                  }}
                >
                  <Finance img={img18} />
                  <Finance img={img19} />
                  <Finance img={img20} />
                  <Finance img={img21} />
                  <Finance img={img22} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </SideBar>
    </div>
  );
}

export default Dashboard;
