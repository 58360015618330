import React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Button, Typography } from "@mui/material";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import FinanceCard from "./FinanceCard";
import DataTable from "./DataTable";
const Finance = () => {
  const styles = {
    flex: {
      padding: "3rem",
      display: "flex",
      flexDirection: "column",

      gap: "0.9rem",
    },
    flex1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5rem",
    },

    flex4: {
      borderRadius: "12px",
      backgroundColor: "#FFFFF0",
      padding: "1rem",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "0.2rem",
    },
    flex5: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },

    flex2: {
      backgroundColor: "lightpink",
      padding: "1rem",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "0.2rem",
    },
    flex3: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
    btn: {
      paddingX: "1.8rem",
      paddingY: "0.5rem",
      borderRadius: "20px",
      fontSize: "0.8rem",

      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "#990000",
      ":hover": {
        backgroundColor: "red",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchFinanceData = async () => {
      try {
        const financeCollection = collection(db, "Finance"); // Replace 'finance' with your actual collection name
        const querySnapshot = await getDocs(financeCollection);
        const financeData = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const mergedData = {
            ...data, // Include all other keys from the document
            ...data.music, // Include the 'music' object
          };
          financeData.push(mergedData);
        });

        // Now, 'financeData' is an array of objects where 'music' object is merged with other keys
        console.log(financeData);
        setData(financeData);
        // You can use 'financeData' for rendering or further processing
      } catch (error) {
        console.error("Error fetching finance data:", error);
        return [];
      }
    };

    fetchFinanceData();
  }, []);
  return (
    <div>
      <SideBar>
        <Box sx={styles.flex}>
          <Box>
            <Typography variant="h4"> Finance</Typography>
          </Box>
          <Box sx={styles.flex1}>
            <DataTable />
            {/* {data.map((val, index) => (
              <FinanceCard
                name={val.albumName}
                artName={val.artistname}
                image={val.image}
                user={val.user}
                userName={val.userName}
                date={val.date}
                index={index + 1}
                key={index}
              />
            ))} */}
          </Box>
        </Box>
      </SideBar>
    </div>
  );
};

export default Finance;
