import React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Button, Typography } from "@mui/material";
import img1 from "../../assests/11.png";
import { Howl, Howler } from "howler";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MicNoneIcon from "@mui/icons-material/MicNone";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MusicCard from "./MusicCard";
import { useNavigate } from "react-router-dom";
import PauseIcon from "@mui/icons-material/Pause";
import { db } from "../../firebaseConfig";
import {
  getDocs,
  collection,
  doc,
  deleteDoc,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
import DeleteIcon from "@mui/icons-material/Delete";
function Music() {
  const [musicList, setMusicList] = useState([]);
  const deleteMusicDocument = async (musicId) => {
    const musicDocRef = doc(db, "Music", musicId); // Assuming your collection is named "Music"
    const res = await deleteDoc(musicDocRef);
    console.log("filter", res);
  };

  async function deleteItemFromArray(docId, arrayField, itemId) {
    try {
      // Reference the document you want to update
      const docRef = doc(db, "Album", itemId); // Replace "YourCollection" with your collection name

      // Get the current data of the document
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();

      // Check if the array field exists in the document
      if (data[arrayField]) {
        // Find the index of the item with the given itemId
        const indexToRemove = data[arrayField].findIndex(
          (item) => item.id === itemId
        );

        if (indexToRemove !== -1) {
          // Use JavaScript's splice to remove the item at the specified index
          data[arrayField].splice(indexToRemove, 1);

          // Update the document with the modified data
          await updateDoc(docRef, { [arrayField]: data[arrayField] });

          console.log(`Removed item with ID ${itemId}`);
        } else {
          console.log("Item not found in the array.");
        }
      } else {
        console.log("Array field does not exist in the document.");
      }
    } catch (error) {
      console.error("Error removing item from array:", error);
    }
  }
  const handleDelete = async (musicId) => {
    try {
      // Perform the delete operation using Firebase
      await deleteMusicDocument(musicId); // Pass the document ID to the delete function
      setMusicList((prevMusicList) =>
        prevMusicList.filter((music) => music.id !== musicId)
      );
    } catch (error) {
      console.error("Error deleting music:", error);
    }
  };
  useEffect(() => {
    const fetchMusic = async () => {
      try {
        const musicCollectionRef = collection(db, "Music");
        const querySnapshot = await getDocs(musicCollectionRef);
        const musicData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setMusicList(musicData);
        console.log(musicData);
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };

    fetchMusic();
  }, []);

  const navigate = useNavigate();
  const styles = {
    flex: {
      padding: "3rem",
      display: "flex",
      flexDirection: "column",

      gap: "0.9rem",
    },
    flex1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5rem",
    },

    flex4: {
      borderRadius: "12px",
      backgroundColor: "#FFFFF0",
      padding: "1rem",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "0.2rem",
    },
    flex5: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },

    flex2: {
      backgroundColor: "lightpink",
      padding: "1rem",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "0.2rem",
    },
    flex3: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
    btn: {
      paddingX: "1.8rem",
      paddingY: "0.5rem",
      borderRadius: "20px",
      fontSize: "0.8rem",

      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "#990000",
      ":hover": {
        backgroundColor: "red",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
  };
  return (
    <SideBar>
      <Box>
        <Box sx={styles.flex}>
          <Box>
            <Button
              variant="contained"
              color="error"
              sx={styles.btn}
              onClick={() => {
                navigate("/AddMusic");
              }}
            >
              Add Music
            </Button>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bolder" }}>
              500 Trending Music
            </Typography>
          </Box>
          <Box sx={styles.flex1}>
            {musicList.map((val) => (
              <MusicCard
                name={val.albumName}
                artName={val.artistName}
                image={val.image}
                audio={val.audio}
                price={val.price}
                duration={val.Duration}
                musicId={val.id} // Pass the document ID as a prop
                onDelete={handleDelete} // Pass the delete function as a prop
              />
            ))}
          </Box>
        </Box>
      </Box>
    </SideBar>
  );
}

export default Music;
