import React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Grid, Typography, Button } from "@mui/material";
import img1 from "../../assests/11.png";
import { useNavigate } from "react-router";
import { collection, getDocs, doc, deleteDoc } from "@firebase/firestore";
import { db } from "../../firebaseConfig";
import CircularProgress from "@mui/material/CircularProgress";
import AlbumCard from "./AlbumCard";
function Album() {
  const navigate = useNavigate();
  const styles = {
    main: {
      padding: "3rem",
    },
    btn: {
      paddingX: "2rem",
      paddingY: "0.5rem",
      borderRadius: "25px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "0.8rem",
      fontWeight: "600",
      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "	#FFAC1C",
      ":hover": {
        backgroundColor: "#FFAC1C",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
    head: {
      paddingY: "0.5rem",

      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      alignItems: "center",
    },
    colFlex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    flex1: {
      paddingY: "10px",
      display: "flex",

      flexDirection: "column",
      gap: "0.5rem",
    },
    absolute: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex2: {
      paddingY: "5px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
  };
  const [albumList, setAlbumList] = useState([]);
  const handleDelete = async (Id) => {
    try {
      // Perform the delete operation using Firebase
      await deleteDocument(Id); // Pass the document ID to the delete function
      setAlbumList((prevAlbumList) =>
        prevAlbumList.filter((blog) => blog.id !== Id)
      );
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const deleteDocument = async (Id) => {
    const musicDocRef = doc(db, "Album", Id); // Assuming your collection is named "Music"
    const res = await deleteDoc(musicDocRef);
    console.log("filter", res);
  };
  useEffect(() => {
    const AlbumData = async () => {
      try {
        const albumCollection = collection(db, "Album");
        const querySnapShot = await getDocs(albumCollection);
        const albumData = querySnapShot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setAlbumList(albumData);
        console.log(albumData);
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };

    AlbumData();
  }, []);
  return (
    <div>
      <SideBar>
        <Box sx={styles.main}>
          <Box sx={styles.head}>
            <Typography
              sx={{
                color: "black",
                fontSize: "1.4rem",
                fontWeight: "700",
                color: "black",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              Music Album
            </Typography>
            <Button
              sx={styles.btn}
              variant="contained"
              onClick={() => {
                navigate("/AddAlbum");
              }}
            >
              Add Album
            </Button>
          </Box>
          <Grid container paddingTop={4} rowSpacing={4}>
            {albumList.map((val) => (
              <Grid item lg={3}>
                <AlbumCard
                  name={val.albumName}
                  img={val.image}
                  id={val.id}
                  onDelete={handleDelete}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </SideBar>
    </div>
  );
}

export default Album;
