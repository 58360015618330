// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyClatT915TiX8xrRsUnPPk7S3aBf58v_6w",
  authDomain: "bizproque.firebaseapp.com",
  projectId: "bizproque",
  storageBucket: "bizproque.appspot.com",
  messagingSenderId: "794897978811",
  appId: "1:794897978811:web:bc150f92ab1d488488209e",
  measurementId: "G-F5RWFWCY8X",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
