import React from "react";
import "../../login.css";
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setUser } from "../../Redux/UserSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../firebaseConfig";
import { useState } from "react";
import { store } from "../../Redux/configureStore";
import {
  Query,
  collection,
  getDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import vBack from "../../assests/banner01.jpeg";
import { TryRounded } from "@mui/icons-material";
const styles = {
  main: {
    background: `url(${vBack}) no-repeat`,
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  justify: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  cont: {
    color: "white",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
    backgroundColor: "#343434",
    borderRadius: "10px 20px 10px 20px",
    maxWidth: { lg: "450px" },
  },
  bold: {
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "bold",
    marginY: "10px",
  },

  btn: {
    paddingX: "2.5rem",
    paddingY: "0.5rem",
    borderRadius: "10px",
    fontSize: "1rem",
    fontWeight: "800",

    transition: "all ease 0.5s",
    color: "#f36a08",
    backgroundColor: "white",
    ":hover": {
      color: "#f36a08",
      backgroundColor: "white",
    },
  },
  field: {
    borderColor: "yellow",
    color: "white !important",
  },
};

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const setState = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };
  const navigate = useNavigate();
  const Submit = async (formdata) => {
    setIsLoading(true);
    console.log(formdata);
    const { username, password } = formdata;

    if (!username || !password) {
      toast.error("Fill all the fields.", {
        position: "top-center",
        autoClose: 2000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log("Please fill all the fields");
      setIsLoading(false);
      return;
    }

    try {
      const adminCollectionRef = collection(db, "admin");
      const adminQuery = query(
        adminCollectionRef,
        where("username", "==", username),
        where("pass", "==", password)
      );
      const querySnapshot = await getDocs(adminQuery);

      if (querySnapshot.size === 1) {
        store.dispatch(setUser(true));
        toast.success("Welcome to Dashboard", {
          position: "top-right",
          autoClose: 2000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      } else {
        toast.error("Invalid ! Login Unsuccessful", {
          position: "top-right",
          autoClose: 3000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Invalid ! Login Unsuccessful", {
        position: "top-right",
        autoClose: 3000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsLoading(false);
      return {
        status: "error",
        error: error.message,
      };
    }
  };
  const handlePress = (e) => {
    if (e.key === "Enter") {
      Submit(formData);
    }
  };
  return (
    <div>
      <Box sx={styles.main}>
        <Container sx={styles.cont} onKeyPress={handlePress}>
          <Box sx={styles.justify}>
            <Typography sx={styles.bold} variant="h5">
              Admin Log in
            </Typography>
          </Box>
          <Grid container rowSpacing={4}>
            <Grid item lg={12}>
              <Box>
                <Typography sx={styles.bold}>Username:</Typography>
              </Box>
              <TextField
                onChange={(e) => {
                  setState("username", e.target.value);
                }}
                sx={styles.field}
                InputProps={{
                  style: {
                    color: "white", // Set the text color to white
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
              <Box>
                <Typography sx={styles.bold}>Password:</Typography>
              </Box>
              <TextField
                type="password"
                sx={styles.field}
                InputProps={{
                  style: {
                    color: "white", // Set the text color to white
                  },
                }}
                onChange={(e) => {
                  setState("password", e.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
              <Box sx={styles.justify}>
                <Button
                  sx={styles.btn}
                  onClick={() => {
                    Submit(formData);
                  }}
                >
                  Log in
                  {isLoading && (
                    <CircularProgress
                      sx={{ color: "#f36a08", marginLeft: "10px" }}
                      size={22}
                    />
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ToastContainer />
    </div>
  );
}

export default Login;
