import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // default: localStorage

// Import your reducer(s)
import userReducer from "./UserSlice";
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedReducer, // Use the persisted reducer for the user state
    // Add other reducers here
  },
});

const persistor = persistStore(store);

export { store, persistor };
