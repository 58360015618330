import React from "react";
import img1 from "../../assests/14.png";
import { Box, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import video from "../../assests/Videos/video.mp4";
function MusicCard() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* <Box>
            <video
              width="100"
              controls
              autoPlay={false}
              style={{ borderRadius: "1rem" }}
            >
              <source src={video} type="video/mp4" />
            </video>
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>Beats Title Name</Typography>
            <Typography>Dreamy</Typography>
          </Box>
        </Box>
        <Box></Box>

        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <PlayArrowIcon
            sx={{
              width: 35,
              height: 35,
              color: "white",
              backgroundColor: "#FEA10B",
              borderRadius: "50%",
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default MusicCard;
