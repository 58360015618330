import React, { useRef } from "react";
import { useState } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Button, Typography, Grid, TextField } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { storage } from "../../firebaseConfig";
import { db } from "../../firebaseConfig";
import { collection, addDoc } from "@firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
function AddAlbum() {
  const styles = {
    main: {
      padding: "3rem",
    },
    head: {
      paddingY: "0.5rem",
      borderBottom: " 1px solid pink",
    },
    colFlex: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    flex1: {
      paddingY: "10px",
      display: "flex",

      flexDirection: "column",
      gap: "0.5rem",
    },
    absolute: {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flex2: {
      paddingY: "5px",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    btn: {
      paddingX: "2.8rem",
      paddingY: "0.5rem",
      borderRadius: "25px",
      fontFamily: "'Poppins', sans-serif",
      fontSize: "0.7rem",
      fontWeight: "600",
      textAlign: "left",
      transition: "all ease 0.5s",
      color: "#white",
      backgroundColor: "	#FFAC1C",
      ":hover": {
        backgroundColor: "#FFAC1C",
        color: "white",
        transition: "all ease 0.5s",
      },
    },
    name: {
      fontFamily: "'Poppins', sans-serif",
      color: "black",
      fontSize: "1rem",
      fontWeight: "bold",
    },
  };

  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState("");
  const setState = (key, value) => {
    setFormData({ ...formData, [key]: value });
    if (formErrors[key]) {
      setFormErrors({ ...formErrors, [key]: "" });
    }
  };

  const Submit = async (form) => {
    console.log(form);
    setIsLoading(true);
    const {
      albumName,

      description,

      albumCover,
    } = form;
    const albumNameMaxLength = 30;

    const descriptionMaxLength = 1000;
    // Matches 1 to 6 digits

    // Error messages
    const errors = {};

    if (!albumName || albumName.trim() === "") {
      setIsLoading(false);
      errors.albumName = "Album name cannot be empty.";
    } else if (albumName.length > albumNameMaxLength) {
      setIsLoading(false);
      errors.albumName = `Cannot exceed ${albumNameMaxLength} characters.`;
    }

    if (!description || description.trim() === "") {
      setIsLoading(false);
      errors.description = "Description cannot be empty.";
    } else if (description.length > descriptionMaxLength) {
      setIsLoading(false);
      errors.description = `Cannot exceed ${descriptionMaxLength} characters.`;
    }

    if (!albumCover) {
      setIsLoading(false);
      toast.error("Image is mandatory", {
        position: "top-right",
        autoClose: 3000, // 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setFormErrors(errors);

    // Check if there are any validation errors
    if (Object.keys(errors).length > 0) {
      setIsLoading(false);
      // Handle validation errors, for example, display error messages
      console.error("Validation errors:", errors);
    } else {
      // Proceed with form submission
      try {
        let url = "";

        try {
          const imageName = albumCover.name;
          const folderName = "Album/";
          const imageRef = ref(storage, folderName + imageName);
          console.log(imageRef);
          const uploadBytesRes = await uploadBytes(imageRef, albumCover);
          console.log(uploadBytesRes);
          url = await getDownloadURL(uploadBytesRes.ref);
          console.log("Imageurl", url);
        } catch (error) {
          console.error("Image upload error:", error);
        }

        const res = await addDoc(collection(db, "Album"), {
          albumName: albumName,
          music: [],
          description: description,

          image: url,
        });
        setIsLoading(false);

        toast.success("Album Added", {
          position: "top-right",
          autoClose: 3000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUploadedImage("");
      } catch (error) {
        toast.error("Album added Unsuccessfull", {
          position: "top-right",
          autoClose: 2000, // 5 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsLoading(false);
        console.log("Not Submitted:", error);
        setUploadedImage("");
      }
    }
  };

  const [files, setFiles] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Handle the selected file here
      setFiles(selectedFile);

      setState("albumCover", selectedFile);

      console.log("Selected file:", selectedFile);
      setUploadedImage(URL.createObjectURL(selectedFile));
    }
  };

  const fileInputRef = useRef(null);

  const handleBoxClick = () => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  return (
    <SideBar>
      <Box sx={styles.main}>
        <Box sx={styles.head}>
          <Typography sx={styles.name}>Add Album</Typography>
        </Box>
        <Grid container paddingTop={2}>
          <Grid item lg={4}>
            <Box sx={styles.flex1}>
              <Box
                sx={{
                  position: "relative",
                  height: "180px",
                  width: "180px",
                  border: "1px solid #FFF8DC",
                  backgroundColor: "#FFF0F5",
                  cursor: "pointer",
                }}
                onClick={handleBoxClick}
              >
                <Box sx={styles.absolute}>
                  <Box sx={styles.colFlex}>
                    {uploadedImage ? (
                      <img
                        src={uploadedImage}
                        style={{ height: "100%", width: "100%" }}
                      />
                    ) : (
                      <>
                        <InsertPhotoIcon />

                        <Typography>Upload an Image</Typography>
                      </>
                    )}

                    <input
                      type="file"
                      accept="image/*" // Specify accepted file types, e.g., images
                      ref={fileInputRef}
                      style={{ display: "none" }} // Hide the input
                      onChange={handleFileChange}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={8}>
            <Grid container columnSpacing={5} rowSpacing={1}>
              <Grid item lg={12}>
                <Box sx={styles.flex1}>
                  <Box>
                    <Typography sx={styles.name}>Album Name</Typography>
                  </Box>
                  <Box>
                    <TextField
                      error={Boolean(formErrors.albumName)}
                      helperText={formErrors.albumName || " "}
                      onChange={(e) => {
                        setState("albumName", e.target.value);
                      }}
                      variant="outlined"
                      sx={{ backgroundColor: "#FFFCFC" }}
                      fullWidth
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={12}>
                <Box sx={styles.flex1}>
                  <Box>
                    <Typography sx={styles.name}>Album Description</Typography>
                  </Box>
                  <Box>
                    <TextField
                      error={Boolean(formErrors.description)}
                      helperText={formErrors.description || " "}
                      onChange={(e) => {
                        setState("description", e.target.value);
                      }}
                      sx={{ backgroundColor: "#FFFCFC" }}
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={7}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={12}>
                <Box sx={styles.flex2}>
                  <Button
                    onClick={() => {
                      Submit(formData);
                    }}
                    variant="contained"
                    sx={styles.btn}
                  >
                    {isLoading && (
                      <CircularProgress
                        size={20}
                        sx={{ marginRight: "10px", color: "white" }}
                      />
                    )}
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </SideBar>
  );
}

export default AddAlbum;
