import React from "react";
import { Box, Typography } from "@mui/material";

function Finance({ img }) {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",

          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          borderRadius: "5px",
          padding: "0.3rem",
        }}
      >
        <img src={img} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography>12.88K</Typography>
          <Typography>Total Songs</Typography>
        </Box>
      </Box>
    </div>
  );
}

export default Finance;
