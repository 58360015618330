import logo from "./logo.svg";
import EditBlogs from "./Pages/Blogs/EditBlogs";
import UpdateVideo from "./Pages/Videos/UpdateVideo";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AlbumDescription from "./Pages/Album/AlbumDescription";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Finance from "./Pages/Finance/Finance";
import Blogs from "./Pages/Blogs/Blogs";
import Album from "./Pages/Album/Album";
import Videos from "./Pages/Videos/Videos";
import Music from "./Pages/Music/Music";
import AddMusic from "./Pages/Music/AddMusic";
import AddAlbum from "./Pages/Album/AddAlbum";
import AddVideos from "./Pages/Videos/AddVideos";
import AddBlogs from "./Pages/Blogs/AddBlogs";
import BlogDescription from "./Pages/Blogs/BlogDescription";
import Event from "./Pages/Events/Event";
import AddEvent from "./Pages/Events/AddEvent";
import Login from "./Pages/Login/Login";
import EventDescription from "./Pages/Events/EventDescription";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setUser } from "./Redux/UserSlice";
import UpdateEvent from "./Pages/Events/UpdateEvent";
import EditAlbum from "./Pages/Album/EditAlbum";
import EditMusic from "./Pages/Music/EditMusic";
function App() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={user ? <Dashboard /> : <Login />} />
          <Route path="/blog" element={user ? <Blogs /> : <Login />} />
          <Route path="/Event" element={user ? <Event /> : <Login />} />
          <Route path="/AddEvent" element={user ? <AddEvent /> : <Login />} />
          <Route path="/album" element={user ? <Album /> : <Login />} />
          <Route path="/video" element={user ? <Videos /> : <Login />} />
          <Route path="/finance" element={user ? <Finance /> : <Login />} />
          <Route
            path="/AddMusic/:id"
            element={user ? <AddMusic /> : <Login />}
          />
          <Route path="/AddAlbum" element={user ? <AddAlbum /> : <Login />} />
          <Route path="/AddVideos" element={user ? <AddVideos /> : <Login />} />
          <Route path="/AddBlog" element={user ? <AddBlogs /> : <Login />} />
          <Route
            path="/BlogDesc/:id"
            element={user ? <BlogDescription /> : <Login />}
          />
          <Route
            path="/BlogDesc/:id"
            element={user ? <BlogDescription /> : <Login />}
          />
          <Route
            path="/AlbumDesc/:id"
            element={user ? <AlbumDescription /> : <Login />}
          />
          <Route
            path="/eventDesc/:id"
            element={user ? <EventDescription /> : <Login />}
          />
          <Route
            path="/editBlog/:id"
            element={user ? <EditBlogs /> : <Login />}
          />

          <Route
            path="/updateVideos/:id"
            element={user ? <UpdateVideo /> : <Login />}
          />

          <Route
            path="/updateEvent/:id"
            element={user ? <UpdateEvent /> : <Login />}
          />

          <Route
            path="/editAlbum/:id"
            element={user ? <EditAlbum /> : <Login />}
          />
          <Route
            path="/editMusic/:id"
            element={user ? <EditMusic /> : <Login />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
