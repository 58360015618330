import { Box, Typography } from "@mui/material";
import React from "react";
import img1 from "../../assests/1 copy.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
function Card({ img }) {
  const style = {
    main: {},
  };
  return (
    <Box style={{ paddingLeft: "0.4rem", paddingRight: "0.4rem" }}>
      <Box sx={{ height: "200px", width: "200px", objectFit: "cover" }}>
        <img src={img} alt="" height={"100%"} width={"100%"} />
      </Box>
    </Box>
  );
}
export default Card;
