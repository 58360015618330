import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "albumName", headerName: "Album Name", width: 220 },
  { field: "artistName", headerName: "Artist Name", width: 200 },
  {
    field: "date",
    headerName: "Date",

    width: 150,
  },
  {
    field: "userName",
    headerName: "Username",

    width: 200,
  },
  {
    field: "user",
    headerName: "Email",

    width: 200,
  },
];

export default function DataTable() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchFinanceData = async () => {
      try {
        const financeCollection = collection(db, "Finance"); // Replace 'finance' with your actual collection name
        const querySnapshot = await getDocs(financeCollection);
        const financeData = [];
        let id = 0;
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          const mergedData = {
            ...data, // Include all other keys from the document
            ...data.music,
            id: ++id, // Include the 'music' object
          };
          financeData.push(mergedData);
        });

        // Now, 'financeData' is an array of objects where 'music' object is merged with other keys
        console.log(financeData);
        setData(financeData);
        // You can use 'financeData' for rendering or further processing
      } catch (error) {
        console.error("Error fetching finance data:", error);
        return [];
      }
    };

    fetchFinanceData();
  }, []);
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        sx={{ fontWeight: "bold", fontFamily: "'Poppins'" }}
        rows={data}
        columns={columns}
      />
    </div>
  );
}
