import React, { useEffect, useState } from "react";
import SideBar from "../../components/Global/SideBar";
import { Box, Button, Typography } from "@mui/material";
import img1 from "../../assests/11.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MicNoneIcon from "@mui/icons-material/MicNone";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { db } from "../../firebaseConfig";
import { getDocs, collection } from "@firebase/firestore";
import { Howl } from "howler";
import DeleteIcon from "@mui/icons-material/Delete";
import PauseIcon from "@mui/icons-material/Pause";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import { Navigate, useNavigate } from "react-router-dom";
function MusicCard({
  name,
  artName,
  audio,
  image,
  price,
  duration,
  onDelete,
  musicId,
}) {
  const [musicList, setMusicList] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSound, setCurrentSound] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchMusic = async () => {
      try {
        const musicCollectionRef = collection(db, "Music");
        const querySnapshot = await getDocs(musicCollectionRef);
        const musicData = querySnapshot.docs.map((doc) => doc.data());
        setMusicList(musicData);
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };

    fetchMusic();
  }, []);
  const styles = {
    flex: {
      padding: "3rem",
      display: "flex",
      flexDirection: "column",

      gap: "0.7rem",
    },
    flex1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.2rem",
    },

    flex2: {
      borderRadius: "12px",
      backgroundColor: " #FFFFE0",
      boxShadow: "10px",
      padding: "1rem",
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "0.2rem",
    },
    flex3: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: "0.5rem",
      width: "35%",
    },

    flex5: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: "1rem",

      width: "30%",
    },
    micicon: {
      width: "20%",
      textAlign: "right",
    },
    micdetail: {
      width: "50%",
      alignItems: "center",
      flex: 1,
      wordBreak: "break-word",
    },
    text: {
      width: "20%",
    },
    name: {
      fontFamily: "'Poppins', sans-serif",
      color: "black",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    musIcon: {
      width: "30%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
  const togglePlay = (musicItem) => {
    if (currentSound && currentSound.playing()) {
      // Pause the currently playing track
      currentSound.pause();
    }

    if (isPlaying) {
      setIsPlaying(false);
      setCurrentSound(null);
    } else {
      const sound = new Howl({
        src: [musicItem],
        html5: true, // Use HTML5 audio
      });

      sound.play();
      sound.duration();

      console.log(sound.duration(), "durat");

      setIsPlaying(true);
      setCurrentSound(sound);
    }
  };
  return (
    <Box sx={styles.flex2}>
      <Box sx={styles.flex5}>
        <Box sx={styles.musIcon}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => togglePlay(audio)}
          >
            {isPlaying ? (
              <PauseIcon
                sx={{
                  width: 35,
                  height: 35,
                  color: "#FEA10B",
                }}
              />
            ) : (
              <PlayArrowIcon
                sx={{
                  width: 35,
                  height: 35,
                  color: "#FEA10B",
                }}
              />
            )}
          </Box>
        </Box>
        <Box sx={styles.text}>
          <Typography sx={styles.name}>{name}</Typography>
        </Box>
      </Box>
      <Box sx={styles.flex3}>
        <Box sx={styles.micicon}>
          <MicNoneIcon fontSize="small" />
        </Box>
        <Box sx={styles.micdetail}>
          <Typography fontFamily={"'Poppins'"}>{artName}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          justifyContent: "center",
          alignItems: "center",
          width: "20%",
          fontWeight: "bold",
        }}
      >
        <Box sx={{ width: "40%" }}>
          <Typography fontFamily={"'Poppins'"}>{duration}</Typography>
        </Box>

        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            onDelete(musicId);
          }}
        >
          <DeleteIcon />
        </Box>
      </Box>
    </Box>
  );
}

export default MusicCard;
